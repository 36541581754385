import { useState, createContext, useContext, useEffect } from "react";
import { get, post, patch } from "../utils/http";
import { PROGRAMS_ENDPOINT, 
  LESSONS_ENDPOINT, 
  LICENSES_ENDPOINT, 
  UPLOAD_PLANIFICATION } from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { AlertsContext } from "./AlertsContext";

const ProgramsContext = createContext();

const ProgramsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [licenses, setLicenses] = useState([])
  const [selectedProgram, setSelectedProgram] = useLocalStorage(
    "selectedProgram",
    ""
  );
  const showSnackbar = useContext(AlertsContext);

  const navigate = useNavigate();

  const createProgram = async (data) => {
    const url = PROGRAMS_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado el programa', 'success')
      fetchPrograms();
      navigate(`/programs/${response.data.id}/upload`)

    } else {
      showSnackbar('Hubo un error al crear el programa', 'error');
    }
  };

  const fetchPrograms = async () => {
    const url = PROGRAMS_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setPrograms(response.data);
    }
  };

  const updateProgram = async ({ programId, values }) => {
    const url = `${PROGRAMS_ENDPOINT}${programId}/`;
    const data = { ...values };
    const response = await patch({ url, data });

    if (!response.error) {
      showSnackbar('Se ha actualizado el programa', 'success')
      fetchPrograms();
      navigate("/programs");
    }else{
      showSnackbar('Hubo un error al actualizar el programa', 'error');
    }
  };

  const fetchLessons = async () => {
    const url = LESSONS_ENDPOINT;
    const response = await get({ url });
    setLessons(response.data);
  };

  const updateLesson = async ({ lessonId, data }) => {
    const url = `${LESSONS_ENDPOINT}${lessonId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar('Se ha actualizado la clase', 'success')
      fetchLessons();
    }else{
      showSnackbar('Hubo un error al actualizar la clase', 'error');
    }
  };

  const fetchLicenses = async () => {
    const url = LICENSES_ENDPOINT;
    const response = await get({ url });

    if (!response.error) {
      setLicenses(response.data);
    }
  };

  const createLicense = async (data) => {
    const url = LICENSES_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha creado la licencia', 'success')
      fetchLicenses();
    } else {
      showSnackbar('Hubo un error al crear la licencia', 'error');
    }
  };

  const uploadPlanifiactionNotFake = async ({data}) => {
    const url = 'https://tecnikids-back.herokuapp.com/lesson/import_lessons/?not_fake=true';
    // const url = UPLOAD_PLANIFICATION + '?not_fake=true';
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('Se ha subido el archivo', 'success')
      fetchPrograms();
    } else {
      showSnackbar('Hubo un error al subir el archivo', 'error');
    }

    return response

  };

  const uploadPlanifiaction = async ({data}) => {
    const url = UPLOAD_PLANIFICATION;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar('La planificación es valida', 'success')
      fetchPrograms();
    } else {
      showSnackbar('Hubo un error al subir el archivo', 'error');
    }

    return response
  };

  

  useEffect(() => {
    //fetchPrograms();
    // fetchLessons();
  }, []);

  const contextData = {
    programs,
    setPrograms,
    selectedProgram,
    setSelectedProgram,
    createProgram,
    fetchPrograms,
    updateProgram,
    fetchLessons,
    updateLesson,
    createLicense,
    uploadPlanifiaction,
    uploadPlanifiactionNotFake,
    lessons,
    licenses
  };

  return (
    <ProgramsContext.Provider value={contextData}>
      {loading ? null : children}
    </ProgramsContext.Provider>
  );
};

const usePrograms = () => {
  return useContext(ProgramsContext);
};

export { ProgramsContext, ProgramsProvider, usePrograms };
