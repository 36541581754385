import React, { useState, useEffect, useContext, useCallback, useReducer } from 'react'
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Chip from '@mui/material/Chip';
import { REPORTS_ENDPOINT, REPORTS_LIST_MONITOR } from "../../../utils/constants";
import { get , patch} from "../../../utils/http";
import Container from "../../Container";
import styles from "./ReportList.module.css";
import { useLoading } from '../../../context/LoadingContext';
import { MonitorFormsContext } from "../../../context/MonitorFormsContext";
import { CurrentUserContext } from "../../../context/CurrentUserProvider";
import LoadingComponent from '../../LoadingComponent';
import { dateFormatter } from '../../../utils/dateFormatter';
import ReportEdit from './ReportEdit';
import ReportePDF from './ReportePDF';
import Modal from '../../Modal';

const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};
const initialState = {
  reports: [],
  pagePre: null,
  pageNext: null,
  currentPage: 1,
  count: 0,
  status_count:0,
  count_all:0,
  draft:0,
  approved:0,
  completed:0,
  totalPages: 0,
  currentReports: [],
  filterMonitor: "",
  filterInstitution: "",
};

const customSort = (rows, selector, direction) => {
  return rows.sort((rowA, rowB) => {
    const aField = selector(rowA);
    const bField = selector(rowB);

    let comparison = 0;

    if (aField > bField || aField > 99.9) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === "desc" ? comparison * -1 : comparison;
  });
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.payload };
    case 'SET_REPORTS':
      return { ...state, ...action.payload, currentPage: state.currentPage + (action.next ? 1 : -1) };
    case 'UPDATE_REPORT_STATUS':
      return {
        ...state,
        reports: state.reports.map(report =>
          report.id === action.payload.id
           ? { ...report, status: action.payload.status, 
            created_at: action.payload.created_at,
          review_date: action.payload.review_date } : report
        ),
      };
      case 'TOGGLE_DOWNLOADED':
        return {
          ...state,
          reports: state.reports.map(report =>
            report.id === action.payload.id
              ? { ...report, download: action.payload.download }
              : report
          ),
        };
   
    default:
      return state;
  }
}

function ReportList() {
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [selectedReport, setSelectedReport] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [{ reports, pagePre, pageNext, currentPage, totalPages, count,
     currentReports, filterMonitor, filterInstitution, draft, approved, completed, status_count, count_all }, dispatch] = useReducer(reducer, initialState);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({
    comentariosGenerales: ""
  });
  const [feedbacks, setFeedbacks] = useState([]);
  const [institution, setInstitution] = useState({ name: "" });
  const [reportReady, setReportReady] = useState(false);
  const [teachersFeedback, setTeachersFeedback] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterTerm, setFilterTerm] = useState("");
  const [listTeachers, setListTeachers] = useState([]);
  const { setSelectedMonitorForm } = useContext(MonitorFormsContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [originalListTeachers, setOriginalListTeachers] = useState([]);
  const [searchTimer, setSearchTimer] = useState(null); // Temporizador para retrasar la búsqueda
  const [currentPageTerm, setCurrentPageTerm] = useState(2);
  const [term, setTerm] = useState('');


  const finalReports =
    currentUser?.role === "IT" || currentUser?.role === "monitoring_coordinator" ||
    currentUser?.role === "monitoring_teacher_3"



  useEffect(() => {
    if (finalReports) {
    showLoading();
    const fetchReports = async () => {
      // const url = `${REPORTS_ENDPOINT}${term}/feedbacks/`;
      const url = `${REPORTS_ENDPOINT}3/feedbacks/`;
      const response = await get({ url });
      const response2 = await get({ url: `${REPORTS_LIST_MONITOR}` });
      if (!response.error) {
        dispatch({
          type: 'SET_STATE',
          payload: {
            reports: response.data.results,
            status_count: response.data.status_count,
            draft: response.data.status_count.draft,
            approved: response.data.status_count.approved,
            completed: response.data.status_count.completed,
            count: response.data.count,
            count_all: response.data.count_all,
            pagePre: response.data.previous,
            pageNext: response.data.next,
            totalPages: Math.ceil(response.data.count / 15),
          }
        });
        if (!response2.error) {
          setListTeachers(response2.data);
        }
       
      
       setOriginalListTeachers(response2.data);
      }
      hideLoading();
    };
    fetchReports(REPORTS_ENDPOINT);
    }
  }, []);

  const handleNextPage = useCallback(async () => {
    if (pageNext) {
      showLoading();
      const response = await get({ url: pageNext });
      if (!response.error) {
        dispatch({
          type: 'SET_STATE',
          payload: {
            reports: response.data.results,
            pagePre: response.data.previous,
            status_count: response.data.status_count,
            draft: response.data.status_count.draft,
            approved: response.data.status_count.approved,
            completed: response.data.status_count.completed,
            count: response.data.count,
            count_all: response.data.count_all,
            pageNext: response.data.next,
            currentPage: currentPage + 1
          }
        });
      }
      hideLoading();
    }
  }, [pageNext, showLoading, hideLoading, currentPage])

  const handlePreviousPage = useCallback(async () => {
    if (pagePre) {
      showLoading();
      const response = await get({ url: pagePre });
      if (!response.error) {
        dispatch({
          type: 'SET_REPORTS',
          payload: {
            reports: response.data.results,
            pagePre: response.data.previous,
            status_count: response.data.status_count,
            draft: response.data.status_count.draft,
            approved: response.data.status_count.approved,
            completed: response.data.status_count.completed,
            count_all: response.data.count_all,
            pageNext: response.data.next,
            count: response.data.count,
            currentPage: currentPage - 1
          },
        });
      }
      hideLoading();
    }
  }, [pagePre, showLoading, hideLoading, currentPage]);

  const handleOpenReports = useCallback((reports) => {
    setOpenModal(true);
    setSelectedReport(reports);
    dispatch({ type: 'SET_STATE', payload: { currentReports: reports } });
  }, []);


  const filteredReports = reports.filter((item) => {
    if (searchText === "" && filterTerm === "" && filterMonitor === "") {
      return item;
    } else {
      let teacherMatch = true;
      const fullNameA = `${item?.teacher_name}`;
      if (searchText !== "") {
        teacherMatch = fullNameA?.toLowerCase().includes(searchText.toLowerCase());
      }

      let termMatch = true;
      if (filterTerm !== "") {
        termMatch = item?.term === filterTerm;
      }

      if (termMatch && teacherMatch) {
        return item;
      }
    }
  });


  // const listTeachers1 = filteredReports.map((report) => report.monitor_name);
  // // Crear una lista única de monitores
  //  const uniqueTeachers = [...new Set(listTeachers)];
  const formatAnswers = (answers, questions) => {
    const newFormData = {};
    newFormData.comentariosGenerales = answers.comentariosGenerales ? answers.comentariosGenerales : "";

    Object.entries(answers).forEach(([section, sectionData]) => {
      if (section !== "comentariosGenerales") {
        const formattedSection = {};
        Object.entries(sectionData).forEach(([questionId, respuesta]) => {
          if (respuesta !== null && respuesta !== "") {
            const preguntaTexto = questions[section].find(p => p.question_id == questionId)?.question;
            formattedSection[preguntaTexto] = respuesta === true ? "Sí" : respuesta === false ? "No" : "No aplica";
          }
        });
        if (Object.keys(formattedSection).length > 0) {
          newFormData[section] = formattedSection;
        }
      }
    });

    return newFormData;
  };

  const showOpenReport = (result) => {
    console.log("teachersFeedback", teachersFeedback);
    console.log("institution", institution);
    const newWindow = window.open("", "_blank");
    newWindow.document.title = "Reporte trimestral";
    newWindow.document.body.innerHTML = `<div id="reporte-container"></div>`;
    ReactDOM.render(
      <ReportePDF teachersFeedback={teachersFeedback}
        institution={institution} />,
      newWindow.document.getElementById("reporte-container")
    );
  };

  function capitalize(word) {
    if (!word) return "";
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  const handleDownloadPDF = async (report) => {
    showLoading();  
    try {
      const response = await get({ url: `${REPORTS_ENDPOINT}${report.id}/` });
      if (response.error) {
        throw new Error("Error al enviar el reporte");
      }
  
      const feedback = response.data.feedback || [];
      const questions = response.data.feedback_questions || [];
      const initialAnswers = { comentariosGenerales: response.data.comments || "" };
      Object.entries(response.data.feedback_questions || {}).forEach(([section, questionsInSection]) => {
        initialAnswers[section] = {};
        questionsInSection.forEach((pregunta) => {
          initialAnswers[section][pregunta.question_id] = pregunta.answer;
        });
      });
  
      setFeedbacks(feedback);
      setQuestions(questions);
      setAnswers(initialAnswers);
  
      await new Promise(resolve => setTimeout(resolve, 0));
      const teacherNameParts = report.teacher_name ? report.teacher_name.split(" ") : [];
      const teacherInfo = {
        firstName: report.teacher_name.split(" ")[0],
        lastName: `${capitalize(teacherNameParts[1]) || ""} ${capitalize(teacherNameParts[2]) || ""} ${capitalize(teacherNameParts[3]) || ""} ${capitalize(teacherNameParts[4]) || ""}`.trim(),
        stages: report.stage,
        elaborado: `${report.created_by} `,
      };
  
      const newFormData = formatAnswers(initialAnswers, questions);
      const feedbackWithTeacher = {
        teacherInfo,
        formData: newFormData,
        comments: initialAnswers.comentariosGenerales,
      };  
      setInstitution({ name: report?.institution_name, id: report?.institution_id });
      setTeachersFeedback([feedbackWithTeacher]);
      setReportReady(true);
  
    } catch (error) {
      console.error(error);
      setReportReady(false);
      alert("Error al enviar el reporte");
    } finally {
      hideLoading();
    }
  };
  
  useEffect(() => {
    if (reportReady && teachersFeedback.length > 0) {
      showOpenReport();
      setReportReady(false);
    }
  }, [reportReady, teachersFeedback]);

  const columns = [
    {
      name: "Seguimiento",
      selector: (row) => row?.monitor_name,
      sortable: true,
      cell: (cell) => (
        <div>{cell?.monitor_name}</div>
      )
    },
    {
      name: "Docente",
      selector: (row) =>
        `${row?.teacher_name}`,
      sortable: true,
      cell: (cell) => (
        <div
          style={{ display: "flex", width: "100px" }}
        >{`${cell?.teacher_name} `}</div>
      ),
    },
    {
      name: "Institución",
      selector: (row) => row?.institution_name,
      sortable: true,
      cell: (cell) => (
        <div
          style={{ display: "flex", width: "200px" }}
        >{`${cell?.institution_name}`}</div>
      ),
    },
    {
      name: "Tipo de docente",
      selector: (row) => row?.type_teacher,
      sortable: true,
      cell: (cell) => (
        <div>
          {cell?.type_teacher}
        </div>
      )
    },
    {
      name: "Creado",
      selector: (row) => row?.created_at,
      sortable: true,
      cell: (cell) => (
        <div>{dateFormatter(cell?.created_at)} </div>
      )
    },
    {
      name: "Revisado",
      selector: (row) => row?.review_date,
      sortable: true,
      cell: (cell) => (
        <div>{dateFormatter(cell?.review_date)} </div>
      )
    },
    {
      name: "Feedback Trimestral",
      selector: (row) => `${row?.status ? row.status : "Borrador"}`,
      cell: (cell) => {
        let statusClass = styles.info; // Default to info for "Completado"
    
        if (cell.status === "Borrador") {
          statusClass = styles.alert;
        } else if (cell.status === "Revisado") {
          statusClass = styles.success;
        } // styles.info is used for "Completado"

        let status
        let chipColor = ""

        if(cell.status === "Completado" ){
          status = "Completado"
           status = cell.status
           chipColor = "#fcb514"
        }else if(cell.status === "Borrador"){
          status = cell.status
           chipColor = "#d30019"
        }else if(cell.status === "Revisado"){
          status = cell.status
          chipColor = "#43b02a"

        }
    
        return (
          <div>
            <Chip label={status} 
            sx={{backgroundColor: chipColor, 
              textTransform: "capitalize",
              width: "fit-content",
              margin: 0,
              height: 25,
              borderRadius: "10px",
              fontWeight: "bold",
              color: '#fff',
              fontSize: '11px',}} />
          </div>
        );
      },
      sortable: true,
    },
  
    {
      name: "Ver Reporte",
      cell: (cell) => (
        <div
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <p onClick={() => handleOpenReports(cell)}
            style={{ cursor: 'pointer', color: 'var(--main-blue)', border: '1px solid var(--main-blue)', borderRadius: '10px', padding: '5px' }}>
            {`Ver Reporte`}
          </p>
        </div>
      ),
    },
    {
      name: "Descargar",
      cell: (cell) => (
        <div
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <i
            className="fas fa-file-pdf"
            style={{ fontSize: "18px", cursor: "pointer", color: "var(--main-blue)" }}
            onClick={() => handleDownloadPDF(cell)}
          ></i>
        </div>
      ),
    },
    {
      name: "Descargado",
      cell: (cell) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <input
            type="checkbox"
            checked={cell.download}
            onChange={() => handleToggleDownloaded(cell.id, !cell.download)}
          />
        </div>
      ),
    }
  ];

  const updateReportDownloadedStatus = async (id, download) => {
    const url = `${REPORTS_ENDPOINT}${id}/put_download/`;
    const response = await patch({ url, data: { download } });
    return response;
  };
  const handleToggleDownloaded = async (id, download) => {
    const response = await updateReportDownloadedStatus(id, download);
    if (!response.error) {
      dispatch({
        type: 'TOGGLE_DOWNLOADED',
        payload: { id, download },
      });
    } else {
      console.error("Error updating download status", response.error);
    }
  };
  const handleUpdateReportStatus = (id, status) => {
    let createdAt = null;
    let reviewDate = null;
    if (status === "Completado") {
      createdAt = new Date().toISOString();
    }
    if (status === "Revisado") {
      createdAt = reports[reports.findIndex((report) => report.id === id)].created_at;
      reviewDate = new Date().toISOString();
    }
    dispatch({
      type: 'UPDATE_REPORT_STATUS',
      payload: { id, status,  created_at: createdAt, review_date: reviewDate },
    });
    setOpenModal(false); // Cerrar el modal después de la actualización
  };
  
  useEffect(() => {
    // Limpia el temporizador si se desmonta el componente
    return () => {
        clearTimeout(searchTimer);
    };
  }, [searchTimer]);

  const searchReports = useCallback(async () => {
    showLoading();
    const queryParams = [];
    if (filterMonitor) {
      const docente = filterMonitor.split(" ")[0];
      queryParams.push(`monitor=${docente}`);
    }

    if (filterInstitution) {
      queryParams.push(`institution=${filterInstitution}`);
    }
    const url = `${REPORTS_ENDPOINT}3/feedbacks/?${queryParams.join('&')}`;
    try {
      const response = await get({ url });
      if (!response.error) {
        dispatch({
          type: 'SET_STATE',
          payload: {
            reports: response.data.results,
            pagePre: response.data.previous,

            draft: response.data.status_count.draft,
            approved: response.data.status_count.approved,
            completed: response.data.status_count.completed,
            pageNext: response.data.next,
            count: response.data.count,
            count_all: response.data.count_all,
            currentPage: 1,
            totalPages: Math.ceil(response.data.count / 15),
          }
        });
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  
    hideLoading();
  }, [showLoading, hideLoading, filterMonitor, filterInstitution, dispatch]);

  const delayedSearch = useCallback(() => {
    clearTimeout(searchTimer);
    const timer = setTimeout(() => {
      searchReports();
    }, 700);
    setSearchTimer(timer);
  }, [searchTimer, filterMonitor, filterInstitution, searchReports]);
  
  useEffect(() => {
    delayedSearch(filterMonitor, filterInstitution);
  }, [filterMonitor, filterInstitution]);

  const handleSearchMonitor = useCallback((selectedValue) => {
    dispatch({ type: 'SET_STATE', payload: { filterMonitor: selectedValue } });
    delayedSearch(selectedValue, filterInstitution);
  }, [dispatch, delayedSearch, filterInstitution]);

  const handleSearchInstitution = useCallback((e) => {
    const selectedValue = e.target.value;
    dispatch({ type: 'SET_STATE', payload: { filterInstitution: selectedValue } });
    delayedSearch(filterMonitor, selectedValue);
  }, [dispatch, delayedSearch, filterMonitor]);

  const handleFilterTermChange = (e) => {
    const selectedValue = e.target.value;
    setTerm(selectedValue);
  };

  const handleTerm = () => {
    setCurrentPageTerm(2);
  };

  return (
    <Container>
      {/* {currentPageTerm === 1 && (
        <div className={styles.wrapper}>
          <div className={styles["search-bar3"]}>
          <select
            value={term}
            className={styles.filter_text}
            onChange={(e) => handleFilterTermChange(e)}
          >
            <option value={""}>Filtrar por lapso</option>
            <option value={1}>I Lapso</option>
            <option value={2}>II Lapso</option>
            <option value={3}>III Lapso</option>
          </select>
          <button className={styles.button} onClick={handleTerm}>Aceptar</button>
          </div>
        </div>
      )
      
      }      */}
      {isLoading && <LoadingComponent />}
      {currentPageTerm === 2 && (
        
      
      <div className={styles.table}>
        <div className={styles["pagination"]} style={{ marginBottom: "10px" }}>
          <i className="fas fa-arrow-left"
            style={{ fontSize: "18px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={handlePreviousPage}></i>
          <span>{`Página ${currentPage}  de ${totalPages}`}</span>
          <i className="fas fa-arrow-right"
            style={{ fontSize: "18px", cursor: "pointer", marginLeft: "5px", marginRight: "5px", color: "var(--main-blue)" }}
            onClick={handleNextPage}></i>
        </div>
        <div className={styles.cont2}>
        <div className={styles["search-bar"]}>
              <select
                value={filterMonitor}
                className={styles.filter_text}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  handleSearchMonitor(selectedValue);
                  setListTeachers([...originalListTeachers]); // Restaurar la lista original
                }}
              >
                <option value={''}>Filtrar por docente de seguimiento</option>
                {listTeachers.map((monitor, index) => (
                <option key={index} value={monitor?.monitor_name}>
                  {monitor?.monitor_name}
                </option>
                ))}
              </select>
        </div>
        <div className={styles["search-bartwo"]}>
          {/* <input 
            type="text"
            placeholder="Filtrar por institución"
            value={filterInstitution}
            onChange={(e) => {
              const selectedValue = e.target.value;
              handleSearchInstitution(selectedValue);
            }}
            className={styles.filter_text}
          /> */}
           <input 
            type="text"
            placeholder="Filtrar por institución"
            value={filterInstitution}
            onChange={handleSearchInstitution}
            className={styles.filter_text}
          />
        </div>
        </div>
        <div className={styles.cont}>
        </div>
        <DataTable
          columns={columns}
          data={filteredReports}
          customStyles={customStyles}
          customSort={customSort}
          sortIcon={
            <i
              className="far fa-arrow-up"
              style={{ marginLeft: "5px", fontSize: "14px" }}
            ></i>
          }
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="800px"
          pointerOnHover
          // onRowClicked={(row) => handleRowClick(row)}
        />
        <div className={styles.questions_total}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "12px" }}>
              Total de reportes:
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {`${count_all}`}
            </p>
            <p style={{ fontWeight: "bold", fontSize: "12px" }}>
              Total en borrador:
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {`${draft}`}
            </p>
            <p style={{ fontWeight: "bold", fontSize: "12px" }}>
              Por revisar:
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {`${completed}` }
            </p>
            <p style={{ fontWeight: "bold", fontSize: "12px" }}>
              Total revisados:
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {`${approved}`}
            </p>
          </div>
        </div>
      </div>
      )}
        {openModal && (
          <Modal title={"Editar Reporte"} modalOpen={setOpenModal}>
            <ReportEdit
              report={currentReports}
              onUpdateReportStatus={handleUpdateReportStatus} // Pasar la función de actualización
            />
          </Modal>
        )}

      
    </Container>
  );
}

export default ReportList;
