import React, {useState} from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import HomeCardComponent from '../Card/HomeCardComponent'
import LoadPictures from '../LoadPictures';

function ActionsComponent() {
    const [openModal, setOpenModal] = useState(false);
  return (
    <Box>
        <h1 style={{margin: '10px 0'}}>Acceso Rápido</h1>
        <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
            <Stack direction={"column"} spacing={1}>
                <HomeCardComponent title={`Carga de Horarios`} color={'var(--sec-purple)'}
                link={"/schedules"} tab={false}
                />
                {/*<HomeCardComponent title={`Registro Fotográfico`} color={'var(--sec-purple)'}
                handleClick={() => setOpenModal(true)}/>*/}

            </Stack>
        </Box>
        {openModal && <LoadPictures setOpenModal={setOpenModal} />}
    </Box>
  )
}

export default ActionsComponent
