import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import linkifyHtml from 'linkify-html';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Button, Box, Tooltip} from "@mui/material";
import { MyTextarea, MySubmitButton } from "../../FormFields";
import YoutubeEmbed from "../../../utils/YoutubePreview";
import extractVideoId from "../../../utils/extractVideoId";
import styles from "./LessonContent.module.css";
import Modal from "../../Modal";
import { EventsContext } from "../../../context/EventsProvider";
import { CurrentUserContext } from "../../../context/CurrentUserProvider";

function LessonContent({ activeLesson, secondaryPlanHeaders, course }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [reportModal, setReportModal] = useState(false)
  const [showIndicators, setShowIndicator] = useState(false);
  const [eventType, setEventType] = useState(null)
  const [incidentType, setIncidentType] = useState(null)
  //const [lessonInfo, setLessonInfo] = useState('')
  const [indicators, setIndicators] = useState(null)
  const {createEvent} = useContext(EventsContext)

  useEffect(() => {
    // Verifica si `achievement_indicators` contiene HTML
    const tieneHtml = /<\/?[a-z][\s\S]*>/i.test(activeLesson?.achievement_indicators || '');
  
    let contenidoSinComillasIndicators;
  
    if (!tieneHtml) {
      // Si no contiene HTML, realiza la separación y el formateo
      contenidoSinComillasIndicators = activeLesson?.achievement_indicators
        ?.replace(/^\s*"|"\s*$/g, "") // Remueve las comillas al principio y al final, incluyendo espacios en blanco
        .split('-') // Divide los indicadores por el guion
        .filter(indicator => indicator.trim() !== "") // Filtra cualquier línea vacía
        .map(indicator => `<li>${indicator.trim()}</li>`) // Envuelve cada indicador en una etiqueta <li>
        .join(''); // Une los elementos para formar una lista
  
      // Agrega los indicadores como elementos de una lista desordenada <ul>
      contenidoSinComillasIndicators = `<ul>${contenidoSinComillasIndicators}</ul>`;
    } else {
      // Si ya tiene HTML, lo asignamos directamente
      contenidoSinComillasIndicators = activeLesson?.achievement_indicators?.replace(/^\s*"|"\s*$/g, "");
    }
  
    setIndicators(contenidoSinComillasIndicators);
  }, [activeLesson]);  

  const handleOpenModal = () => {
    setReportModal(true)
  }

  const handleChangeType = (e) => {
    setEventType(e.target.value)
  }
  
  const handleChangeIncidentType = (e) => {
    setIncidentType(e.target.value)
  }

  const mainActivites = activeLesson?.main_activities
  ?.split('-') // Divide las actividades por el guion
  .filter(actividad => actividad.trim() !== "") // Filtra cualquier línea vacía
  .map((actividad, index) => (
    <li key={index} style={{ fontSize: "1.3rem" }}>{actividad?.trim()}</li> // Envuelve cada actividad en un <li>
  ));

  return (
    <div className={styles.lesson_info}>
      <Box sx={{width:'100%', display:'flex', gap: '10px'}}>
        {activeLesson?.ia_resources && activeLesson?.ia_resources.length > 0 && (
            <Tooltip
              title={
                <Box component="ul" sx={{ paddingLeft: 2, margin: 0, fontSize: '12px' }}>
                  {activeLesson?.ia_resources?.map((item, index) => (
                    <Link to={item.url} target="_blank">
                      <li key={index}>{item.description}</li>
                    </Link>
                  ))}
                </Box>
              }
              placement="top"
              arrow
              sx={{ fontSize: '0.9rem' }}
            >
              <Box sx={{backgroundColor: 'var(--main-blue)', 
                borderRadius: '10px', display:'flex', alignItems: 'center'}}>
                <img src="/ai_icon.png" alt="AI Tips" style={{ cursor: 'pointer', width: '35px',
                height: '35px', margin: '2px'}} />
              </Box>
            </Tooltip>
          )}
        <Button variant="outlined" color="error" 
        sx={{borderRadius:'10px',  marginLeft: 'auto'}}
          onClick={handleOpenModal}>
          Reportar problema
        </Button>
      </Box>
      <div className={styles.title_container}>
        <div>
          <h1>{activeLesson?.title}</h1>
          <h3>{activeLesson?.description}</h3> 
        </div>
      </div>
      {(activeLesson?.lesson_goal && activeLesson?.lesson_goal?.toLowerCase() !== "nan") && (
        <>
          <h2>Objetivo de la sesión</h2>
          <div className={styles.text}>{activeLesson?.lesson_goal}</div>
        </>
      )}
      <h2>Actividades</h2>
      {activeLesson &&
        secondaryPlanHeaders.activities[3].keys.map((item, idx) => {
          let title;
          if (item === "beginning") {
            title = "Inicio";
          } else if (item === "development") {
            title = "Desarrollo";
          } else if (item === "closure") {
            title = "Cierre";
          }
          const info =
            activeLesson[item]?.includes("\n") &&
            activeLesson[item]
              ?.split("\n")
              .filter((para) => para.trim() !== "");
          const contenidoSinComillas = activeLesson[item]?.replace(
            /^"|"$/g,
            ""
          );
          return (
            <div key={item} className={styles.container_text}>
              <div className={styles.title_text}>{title}</div>
              {info ? (
                <>
                  {info?.map((data) => {
                    return (
                      <p className={styles.text} key={data}>
                        {data}
                      </p>
                    );
                  })}{" "}
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: contenidoSinComillas }}
                  style={{ fontSize: "1.3rem" }}
                />
              )}
            </div>
          );
        })}
      {(activeLesson?.main_activities && activeLesson?.main_activities?.toLowerCase() !== "nan") && (
        <div>
          <h2>Actividad de evaluación</h2>
          <p style={{ fontSize: "1.3rem" }}>{mainActivites}</p>
        </div>
      )}
      {(indicators && activeLesson?.achievement_indicators !== "nan" && activeLesson?.achievement_indicators !== `"<p>.</p>"` && activeLesson?.achievement_indicators !== `"<p>nan</p>"`) && (
        <div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => setShowIndicator(!showIndicators)}
          >
            <h2>Indicadores</h2>
            {showIndicators ? (
              <i
                className="fas fa-chevron-up"
                style={{ marginLeft: "20px", fontSize: "11px" }}
              ></i>
            ) : (
              <i
                className="fas fa-chevron-down"
                style={{ marginLeft: "20px", fontSize: "11px" }}
              ></i>
            )}
          </div>
          {showIndicators && (
            <div className={styles.container_text}>
              {indicators ? (
                <>
                  <div dangerouslySetInnerHTML={{
                        __html: linkifyHtml(indicators, {
                          attributes: {
                            target: "_blank",
                          },
                        }),
                      }}
                />
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: indicators }}
                  style={{ fontSize: "1.3rem" }}
                />
              )}
              
            </div>
          )}
        </div>
      )}
      {(activeLesson?.materials?.length > 0) && (
        <div>
          <div
            style={{ display: "flex", }}
          >
            <h2>Materiales</h2>
          </div>
          <div className={styles.container_text}>
            {activeLesson?.materials?.map((material) => {
              return (
              <p className={styles.text_2}>- {material.content}</p>);
            })}
          </div>
        </div>
      )}
      {activeLesson?.resources?.length > 0 && (
        <>
          <h2>Recursos</h2>
          <div className={styles.resources_videos}>
            {activeLesson?.resources?.map((resource, i) => {
              return (
                <div key={i}>
                  {resource?.type === "SCREENSHOT" && (
                    <Link to={resource?.url} 
                    style={{textDecoration: 'none'}} 
                    target="_blank" rel="noreferrer">
                      <Button size="small" 
                      variant="outlined"
                      sx={{fontSize: '14px', 
                      borderRadius: '10px',  
                      marginRight: "20px",}}>
                        Imagen Recurso
                      </Button>
                    </Link>
                  )}
                  {resource.type === "RESOURCE" &&
                  <>
                  {
                    resource?.url?.includes("youtu") ? (
                      <YoutubeEmbed
                        url={resource?.url}
                        embedId={extractVideoId(resource?.url)}
                      />
                    ) : (
                      <Link to={resource?.url} 
                      style={{textDecoration: 'none'}} 
                      target="_blank" rel="noreferrer">
                        <Button size="small" 
                        variant="outlined"
                        sx={{fontSize: '14px', 
                        borderRadius: '10px',  
                        marginRight: "20px",}}>
                          Ver Recurso
                        </Button>
                      </Link>
                    )
                  }
                  </>
                  }
                  {resource?.type === "PROGRAM" && (
                    <Link to={resource?.url} 
                    style={{textDecoration: 'none'}} 
                    target="_blank" rel="noreferrer">
                      <Button size="small" 
                      variant="outlined"
                      sx={{fontSize: '14px', 
                      borderRadius: '10px',  
                      marginRight: "20px",}}>
                         Ver Recurso - Programa
                      </Button>
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
      {reportModal && 
      <Modal title={"Reportar problema"} 
      modalOpen={setReportModal}>
          <div style={{width: '100%', maxHeight: '80vh', overflowY: 'scroll', display: 'flex', 
          flexDirection: 'column', gap: '15px', justifyContent:'center'}}>
            <Formik
              initialValues={{
                description: "",
              }}
              validationSchema={Yup.object({
                description: Yup.string().required("Campo requerido"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                const data = {
                  "type_event":eventType,
                  "institution":course?.institution?.id,
                  "description":values.description,
                  "incident_type":incidentType,
                  "program": course.program_id,
                  "report_person": currentUser.id,
                  "event_date": new Date()
                }
                createEvent({data})
                setReportModal(false)
                setTimeout(() => {
                  setSubmitting(false);
                }, 400);
              }}
            >
              <Form className={styles["form__fields"]}>
                <div style={{display:'flex', gap: '10px'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                  <label for="type_event" style={{marginBottom: '3px'}}>
                  Tipo de Reporte
                  </label>
                  <select id="type_event" name="type_event" onChange={(e) => handleChangeType(e)}>
                    <option value={null}>{"---"}</option>
                    <option value={"Incidencia"}>{"Incidencia"}</option>
                    <option value={"Solicitud"}>{"Solicitud"}</option>
                    <option value={"Evento"}>{"Evento"}</option>
                  </select>
                </div>
                {eventType === "Incidencia" && (
                  <div style={{display:'flex', flexDirection:'column'}}>
                    <label for="incident_type" style={{marginBottom: '3px'}}>
                    Tipo de Incidencia
                    </label>
                    <select id="incident_type" name="type_event" onChange={(e) => handleChangeIncidentType(e)}>
                      <option value={null}>{"---"}</option>
                      <option value={"Lost Equipment"}>{"Perdida de equipo"}</option>
                      <option value={"Damaged Equipment"}>{"Equipo Dañado"}</option>
                      <option value={"Other"}>{"Otro"}</option>
                      
                    </select>
                  </div>
                ) || ( eventType === "Solicitud" || eventType === "Evento" ) && (
                  <>
                  <div style={{display:'flex', flexDirection:'column'}}>
                    <label for="incident_type" style={{marginBottom: '3px'}}>
                    Canal de Reporte
                    </label>
                    <select id="incident_type" name="type_event" onChange={(e) => handleChangeIncidentType(e)}>
                      <option value={null}>{"---"}</option>
                      <option value={"Meeting"}>{"Reunión"}</option> 
                      <option value={"Chat"}>{"Chat"}</option> 
                      <option value={"Call"}>{"Llamada"}</option> 
                      <option value={"Other"}>{"Otro"}</option>
                      
                    </select>
                  </div>
                  </>
                )
                }
                
                </div>
                <MyTextarea label="Descripción del problema" name="description" />
                {eventType !== null && 
                <MySubmitButton name="Reportar" type="submit" />}
              </Form>
            </Formik>
          </div>
      </Modal>}
    </div>
  );
}

export default LessonContent;
