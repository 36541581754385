import React, { useContext, useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { Link, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Container from "@mui/material/Container"
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import MenuIcon from "@mui/icons-material/Menu"
import IconButton from "@mui/material/IconButton"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import styles from "./Navbar.module.css";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import { UsersContext } from "../../context/UsersProvider";
import { NOTIFICATIONS_ENDPOINT } from "../../utils/constants";
import { get, patch } from "../../utils/http";
import MobileMenu from "./MobileMenu";
import { useConversations } from "../../context/ConversationsProvider";
import NotificationsMenu from "./NotificationsMenu";
import NotificationSnack from "../Notifications/NotificationSnack";

const Navbar = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messageData, setMessageData] = useState({
    message: '',
    firstName: '',
    lastName: ''
  });
  const [menuOpen, setMenuOpen] = useState(false)
  const { currentUser } = useContext(CurrentUserContext);
  const { fetchSingleUser, userAct } = useContext(UsersContext);

  const navigate = useNavigate()

  const {
    conversations,
    currentConversationId,
    fetchRooms,
    fetchMessages
  } = useConversations();

  useEffect(() => {
    if(currentUser.role !== 'student' && conversations?.length > 0){
      const sockets = {};
    
    conversations?.forEach((room) => {
      const socketUrl = `wss://tecnikids-back.herokuapp.com/ws/chat/${room.id}/?token=${currentUser.tokens.access}`;
      const socket = new WebSocket(socketUrl);
      sockets[room.id] = socket;

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const messageInfo = data.message

        setMessageData({
          message: messageInfo.message,
          firstName: messageInfo.created_by.first_name,
          lastName: messageInfo.created_by.last_name
        });

        const audio = new Audio('/notification.mp3');
        audio.play();

        setOpenSnackbar(true);
        fetchRooms();
        if(currentConversationId){
         fetchMessages()
        }
      };
    });

    return () => {
      Object.values(sockets).forEach((socket) => {
        socket.close();
      });
    };
    }
  }, [conversations, currentUser]);

  const newConversationMessage = conversations?.filter((item) => item.unread_messages > 0)

  const [hasNotifications, setHasNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  //WEB SOCKET Notifications
  const [socketUrl, setSocketUrl] = useState(
    currentUser
      ? `wss://tecnikids-back.herokuapp.com/ws/notifications/?token=${currentUser.tokens.access}`
      : null,
    {
      onOpen: () => console.log("opened"),
      //Will attempt to reconnect on all close events, such as server shutting down
      // shouldReconnect: (closeEvent) => true,
      onClose: () => {
        console.log("Disconnected!");
      },
      onMessage: (e) => {
        const data = JSON.parse(e.data);
        switch (data.type) {
          case "welcome_message":
            break;
          default:
            throw new Error("Unknown message type!");
        }
      },
    }
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickNotification = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))


  const { lastMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      fetchNotifications();
    }
  }, [lastMessage]);

  //GET Notifications
  //useEffect(() => {
  //  fetchNotifications();
  //}, []);

  const fetchNotifications = async () => {
    const url = `${NOTIFICATIONS_ENDPOINT}`;
    const response = await get({ url });
    setNotifications(response.data);
  };

  const readNotification = async (notificationId, notificacionURL) => {
    const url = `${NOTIFICATIONS_ENDPOINT}${notificationId}/`;
    const data = { status: 3 };
    const response = await patch({ url, data });
    if (!response.error) {
      fetchNotifications();
    }
    navigate(notificacionURL)
  };

  //Filter notifications
  useEffect(() => {
    if(currentUser.role !== 'student'){
    const filterNotifications = () => {
      const currentDateTime = new Date();
      const thirtyDaysAgo = new Date(currentDateTime?.setDate(currentDateTime?.getDate() - 30));
      const fiveDaysAgo = new Date(currentDateTime?.setDate(currentDateTime?.getDate() - 5));

      const filtered = notifications?.filter(notification => {
        const creationDateTime = new Date(notification?.creation_date);
        if (notification?.status === 1 && creationDateTime >= thirtyDaysAgo) {
          return true;
        } else if (notification?.status === 3 && creationDateTime >= fiveDaysAgo) {
          return true; 
        }
        return false;
      });

      setFilteredNotifications(filtered);
    };

    filterNotifications();
    }
  }, [notifications]);

  const filterUnreadNotifications = filteredNotifications?.filter((item) => item.status === 1)

  //Check if user has notifications
  useEffect(() => {
    const valor = [];
    if(currentUser.role !== "student"){
      filteredNotifications?.forEach((not) => {
      valor.push(not.status);
    });
    if (valor.includes(1) || valor.includes(2)) {
      setHasNotifications(true);
    } else {
      setHasNotifications(false);
    }}
  }, [notifications])

  //Order notifications by status
  if(currentUser.role !== "student"){
    notifications?.sort((a, b) => {
    if (a.status === 3) {
      return 1;
    } else if (b.status === 3) {
      return -1;
    } else {
      return 0;
    }
  });}

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  return (
    <>
    <AppBar
      position="sticky" elevation={0}
      sx={{backgroundColor: isDesktop ? '#fff' : 'var(--main-blue)', width: '100%',
       }}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar>
          <Link to="/">
            <img style={{width:'174px'}}
            src={isDesktop ? "https://kuriosedu.s3.us-east-2.amazonaws.com/media/logos/LogoKuriospRUEBA.png" : "/KURIOS_neg.webp"} />
          </Link>
        <Box  
        sx={{ justifyContent: "flex-end", 
        display: "flex", 
        alignItems:'center', width: '100%' }}>
          {(currentUser) && (
            <>
              {(currentUser.role !== "student") &&
              <li className={styles.item}>
                <Link to="/chats" className={styles.link}>
                  <i className="fal fa-comment"></i>
                  {newConversationMessage?.length >0 && 
                  <div className={styles.icon_dot_chat} >
                    <p>{newConversationMessage?.length}</p>
                  </div>
                  }
                </Link>
              </li>}
              {currentUser.role !== "student" && 
              <li className={styles.item}>
                <div
                  onClick={handleClickNotification}
                  className={styles.notifications}
                >
                  <i className="fal fa-bell" style={{ cursor: "pointer" }}></i>
                  {hasNotifications && 
                  <div className={styles.icon_dot} >
                    <p>{filterUnreadNotifications?.length}</p>
                  </div>}
                </div>
              </li>}
              <NotificationsMenu open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl}
              filteredNotifications={filteredNotifications} 
              readNotification={readNotification} />
              {currentUser.role !== "student" && isDesktop &&
              <li
                className={styles.item_profile}
                onClick={() => fetchSingleUser(currentUser?.id)}
              >
                <Link to="/profile" className={styles.link} 
                style={{textDecoration: 'none', color: 'var(--main-blue)'}}> 
                <div className={styles.profile}>
                  {userAct?.img_profile ? 
                    <img src={userAct?.img_profile} alt="user"
                    style={{borderRadius: '50%', width: '35px', height: '35px'}}/> :
                    <i className="fas fa-user-circle" style={{color: 'var(--main-blue)'}}></i>}
                  <div className={styles.name}>
                    {`${currentUser["last_name"]}, ${currentUser["first_name"]}`}
                  </div>
                </div>
                </Link> 
              </li>}
              {!isDesktop &&
                <IconButton
                onClick={() => setMenuOpen(!menuOpen)}>
                  <MenuIcon fontSize="large" sx={{color: '#fff'}} />
                </IconButton>
              }
            </>
          )}
        </Box>
        </Toolbar>
      </Container>
      <NotificationSnack openSnackbar={openSnackbar}
      handleCloseSnackbar={handleCloseSnackbar}
      messageData={messageData} />
    </AppBar>
    <MobileMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} 
    userAct={userAct}  />
    </>
  );
};

export default Navbar;