import { useState, createContext, useContext, useEffect } from "react";
import { get, post, patch, deleted } from "../utils/http";
import {
  MONITOR_FORM_ENDPOINT,
  QUESTIONS_FORM_ENDPOINT,
  MONITOR_ANSWERS_FORM_ENDPOINT,
  MONITOR_PICTURES_ENDPOINT,
  TEMPLATE_FORM_ENDPOINT,
} from "../utils/constants";
import { useCurrentUser } from "./CurrentUserProvider";
import { AlertsContext } from "./AlertsContext";
import useLocalStorage from "../hooks/useLocalStorage";

const MonitorFormsContext = createContext();

const MonitorFormsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [monitorForm, setMonitorForm] = useState([]);
  const [createdForm, setCreatedForm] = useState({});
  const [answers, setAnswers] = useState([]);
  const [pictures, setPictures] = useState([])
  const [selectedMonitorForm, setSelectedMonitorForm] = useLocalStorage(
    "selectedMonitorForm",
    ""
  );

  const showSnackbar = useContext(AlertsContext);

  const { currentUser } = useCurrentUser();

  const createQuestion = async ({ data }) => {
    const url = QUESTIONS_FORM_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado la pregunta", "success");
      fetchQuestions();
    } else {
      showSnackbar("Hubo un error al crear la pregunta", "error");
    }
  };

  const createPlanilla = async ({ data }) => {
    const url = TEMPLATE_FORM_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se ha creado la planilla", "success");
    } else {
      showSnackbar("Hubo un error al crear la planilla", "error");
    }
  };

  const editQuestion = async ({ data }) => {
    const url = QUESTIONS_FORM_ENDPOINT;
    const response = await patch({ url, data });
    if (!response.error) {
      showSnackbar("Se ha editado la pregunta", "success");
      fetchQuestions();
    } else {
      showSnackbar("Hubo un error al editar la pregunta", "error");
    }
  };

  const fetchQuestions = async () => {
    const url = QUESTIONS_FORM_ENDPOINT;
    const response = await get({ url });

    if (!response.error) {
      setQuestions(response.data);
    }
  };

  const fetchPictures = async () => {
    const url = MONITOR_PICTURES_ENDPOINT;
    const response = await get({ url });

    if (!response.error) {
      setPictures(response.data);
    }
  };

  const createPicture = async ({ data }) => {
    const url = MONITOR_PICTURES_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Se han guardado las imágenes", "success");
      fetchPictures();
    } else {
      showSnackbar("Hubo un error al guardar las imágenes", "error");
    }
  };

  const fetchMonitorForm = async () => {
    const url = MONITOR_FORM_ENDPOINT;
    const response = await get({ url });

    if (!response.error) {
      setMonitorForm(response);
    }
  };

  const sendMonitorForms = async ({ data }) => {
    const url = MONITOR_FORM_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Planilla enviada satisfactoriamente", "success");
      fetchMonitorForm();
    } else {
      showSnackbar("Hubo un error al enviar la planilla", "error");
    }
  };

  const fetchFormAnswers = async () => {
    const url = MONITOR_ANSWERS_FORM_ENDPOINT;
    const response = await get({ url });
    if (!response.error) {
      setAnswers(response.data);
    }
  };

  const editMonitorForm = async ({ planillaId, data }) => {
    const url = `${MONITOR_FORM_ENDPOINT}${planillaId}/`;
    const response = await patch({ url, data });
    if (!response.error) {
      fetchMonitorForm();
      showSnackbar("Se ha editado el form", "success");
    } else {
      showSnackbar("Hubo un error al editar el form", "error");
    }
  };

  const deleteMonitorForm = async ({ planillaId }) => {
    const url = `${MONITOR_FORM_ENDPOINT}${planillaId}/`;
    const response = await deleted({ url });
    if (!response.error) {
      fetchMonitorForm();
      showSnackbar("Se ha eliminado la planilla", "success");
    } else {
      showSnackbar("Hubo un error al eliminar la planilla", "error");
    }
    return response;
  };

  useEffect(() => {}, [currentUser]);

  const contextData = {
    createQuestion,
    createPlanilla,
    fetchQuestions,
    fetchMonitorForm,
    sendMonitorForms,
    fetchFormAnswers,
    questions,
    monitorForm,
    answers,
    setAnswers,
    editQuestion,
    selectedMonitorForm,
    setSelectedMonitorForm,
    editMonitorForm,
    deleteMonitorForm,
    createPicture
  };

  return (
    <MonitorFormsContext.Provider value={contextData}>
      {loading ? null : children}
    </MonitorFormsContext.Provider>
  );
};

const useForms = () => {
  return useContext(MonitorFormsContext);
};

export { MonitorFormsContext, MonitorFormsProvider, useForms };
