import React, { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import {
  MyTextInput,
  MySubmitButton,
  MyTextarea,
  MySelect,
} from "../../FormFields";
import Container from "../../Container";
import {
  INSTITUTIONS_ENDPOINT,
  INSTITUTIONS_LIST_ENDPOINT,
  TEMPLATE_FORM_ENDPOINT,
} from "../../../utils/constants";
import { get } from "../../../utils/http";
import { MonitorFormsContext } from "../../../context/MonitorFormsContext";
import styles from "./Planillas.module.css";
import LoadingComponent from "../../LoadingComponent";
import { useLoading } from "../../../context/LoadingContext";

function Planillas() {
  const [institutions, setInstitutions] = useState([]);
  const { sendMonitorForms } = useContext(MonitorFormsContext);
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [questions, setQuestions] = useState(false);
  const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedTeacher2, setSelectedTeacher2] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [userResponses, setUserResponses] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const { showLoading, hideLoading, isLoading } = useLoading();

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstitutions = async () => {
      showLoading();
      const url = INSTITUTIONS_LIST_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setInstitutions(response.data);
        hideLoading();
      } else if (response.error === 401) {
        hideLoading();
      }
    };
    fetchInstitutions();
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      showLoading();
      const url = TEMPLATE_FORM_ENDPOINT;
      const response = await get({ url });
      if (!response.error) {
        setTemplates(response.data);
        hideLoading();
      } else if (response.error === 401) {
        hideLoading();
      }
    };
    fetchTemplates();
  }, []);

  // const fetchCourses = async (institutionId) => {
  //   showLoading();
  //   const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/courses/`;
  //   const response = await get({ url });
  //   setFilteredCourses(response.data);
  //   hideLoading();
  // };
  const fetchCourses = async (institutionId) => {showLoading();
    try {
      const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/coursesforspreadsheet/`;
      const response = await get({ url });
      setFilteredCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    } finally {
    }
  };  


  useEffect(() => {
    if (selectedTemplate) {
      const filterQuestions = templates?.filter(
        (item) => item.id === parseInt(selectedTemplate)
      );
      const questionsF = filterQuestions[0].questions;
      setQuestions(questionsF);
    }
  }, [selectedTemplate, templates]);

  const fetchTeachers = async (institutionId) => {
    const url = `${INSTITUTIONS_ENDPOINT}${institutionId}/teachers/`;
    const response = await get({ url });

    setTeachers(response.data);
  };

  const handleTeacherChange = (e) => {
    showLoading();
    teachers.forEach((teacher) => {
      if (teacher.id === parseInt(e.target.value)) {
        setSelectedTeacher(teacher);
      }
    });
    hideLoading();
  };

  const handleTeacherChange2 = (e) => {
    showLoading();
    teachers?.forEach((teacher) => {
      if (teacher.id === parseInt(e.target.value)) {
        setSelectedTeacher2(teacher);
      }
    });
    hideLoading();
  };

  const sortedInstitutions =
    institutions &&
    institutions?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  const sortedQuestions =
    questions &&
    questions?.sort((a, b) => {
      return a.area.localeCompare(b.area);
    });

  const groupedData =
    sortedQuestions &&
    sortedQuestions?.reduce((acc, item) => {
      if (!acc[item.area]) {
        acc[item.area] = [];
      }
      acc[item.area].push(item);
      return acc;
    }, {});

  const handleInstitution = async(e) => {
    showLoading();
    const instId = parseInt(e.target.value);
    setSelectedInstitutionId(instId);
    // para que espere la respuesta de fetchCourses y fetchTeachers
    try {
      await Promise.all([fetchCourses(instId), fetchTeachers(instId)]);
    } catch (error) {
      console.error('Error fetching data:', error);
      hideLoading();
    } finally {
      hideLoading();
    }
  };
  

  useEffect(() => {
    if (selectedInstitutionId) {
    }
  }, [selectedInstitutionId]);
  const handleTeachers = (e) => {
    const courseId = parseInt(e.target.value);
    showLoading();
    const course = filteredCourses.filter(
      (item) => item.id === parseInt(e.target.value)
    );
    console.log(course[0]);
    setSelectedCourse(course[0]);
    hideLoading();

    {
      /*const filtered = teachers?.filter((item) => 
    selectedCourse.teachers.includes(item.id))

    setFilteredTeachers(filtered)*/
    }
  };

  const handleResponseChange = (questionId, answer, comment, applies) => {
    const existingResponse = userResponses.find(
      (response) => response.question === questionId
    );

    if (existingResponse) {
      const updatedResponses = userResponses.map((response) =>
        response.question === questionId
          ? {
              ...response,
              answer,
              comment: comment !== undefined ? comment : response.comment,
              applies,
            }
          : response
      );
      setUserResponses(updatedResponses);
    } else {
      const newResponse = { question: questionId, answer, applies };
      if (comment !== undefined) {
        newResponse.comment = comment;
      }
      setUserResponses([...userResponses, newResponse]);
    }
  };

  const handleSendResponse = (values) => {
    const payload = {
      course_section: parseInt(values.course_section),
      teachers: [
        {
          id: selectedTeacher?.info?.id,
          feedback: values?.feedback,
        },
        {
          id: selectedTeacher2?.info?.id,
          feedback: values?.feedback2,
        },
      ],
      comments: values.comments,
      teacher_type: selectedTeacher.type,
      date: values.date,
      estimated_grade: values.estimated_grade,
      lesson: selectedClass,
      route: values.route,
      answers: userResponses.map((response) => ({
        question: response.question,
        answer: response.answer,
        comment: response.comment,
        not_applies: response.applies,
      })),
      form_template: selectedTemplate,
    };

    if (!selectedTeacher2) {
      payload.teachers.pop();
    }
    sendMonitorForms({ data: payload });
  };

  return (
    <Container>
      <div className={styles["create-question"]}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <Formik
          initialValues={{
            comments: "",
            course_section: 0,
            date: new Date(),
            feedback: "",
            estimated_grade: 0,
            lesson: "",
            route: 0,
          }}
          validationSchema={Yup.object({
            estimated_grade: Yup.number().required("Campo requerido"),
            course_section: Yup.number(),
            route: Yup.number().required("Campo requerido"),
            // lesson: Yup.string().required("Campo requerido"),
            feedback: Yup.string().required("Campo requerido"),
            comments: Yup.string().required("Campo requerido"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              handleSendResponse(values);
              setSubmitting(false);
            }, 400);
            resetForm();
          }}
        >
          <Form className={styles["form__fields"]}>
            <Grid container direction={"row"} rowSpacing={2} columnSpacing={2} sx={{width: isDesktop ? '60%' : '100%'}}>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Institución</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      value={selectedInstitutionId}
                      onChange={handleInstitution}
                  >
                    {institutions?.map((institution) => (
                    <MenuItem value={institution.id} key={institution.id}>
                      {`${institution?.name}`}
                    </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Curso</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={handleTeachers}
                      value={selectedCourse?.id}
                  >
                    {filteredCourses.length>0 &&
                      filteredCourses?.map((course) => (
                        <MenuItem value={course.id} key={course.id}>
                          {`${course?.institution_level} ${course?.program}`}
                        </MenuItem>
                      ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
              <MySelect name="course_section" label={"Seccion"}>
              {selectedCourse && selectedCourse?.sections && selectedCourse?.sections?.map((section) => (
                <MenuItem value={section.id} key={section.id}>
                  {section.name}
                </MenuItem>
              ))}
              </MySelect>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Planilla</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedTemplate(e.target.value)}
                  >
                  {templates?.map((template) => (
                    <MenuItem value={template.id} key={template.id}>
                      {`${template?.name} - ${template?.term} Lapso`}
                    </MenuItem>
                  ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Docente</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => handleTeacherChange(e)}
                  >
                  {teachers && teachers?.map((teacher) => (
                    <MenuItem value={teacher.id} key={teacher?.info?.id}>
                      {`${teacher?.info?.first_name} ${teacher?.info?.last_name}`}
                    </MenuItem>
                  ))}
                      
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Docente 2 (si aplica)</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => handleTeacherChange2(e)}
                  >
                  {teachers?.map((teacher) => (
                    <MenuItem value={teacher.id} key={teacher?.info?.id}>
                      {`${teacher?.info?.first_name} ${teacher?.info?.last_name}`}
                    </MenuItem>
                  ))}
                      
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
              <MyTextInput
                label="Fecha"
                name="date"
                type="date"
                placeholder="Date"
                icon="fa fa-user"
              />

              </Grid>
              <Grid item md={6} sm={12} xs={12}>
              <MyTextInput
                label="Nota subjetiva"
                name="estimated_grade"
                type="number"
                placeholder="Nota"
                icon="fa fa-user"
              />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Lapso</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedTerm(e.target.value)}
                  >
                  {selectedCourse?.lessons &&
                    Object.keys(selectedCourse?.lessons).map((term) => {
                      let terms = {
                        first_term: "1er Lapso",
                        second_term: "2do Lapso",
                        third_term: "3er Lapso",
                      };

                      return (
                        <MenuItem value={term} key={term}>
                          {`${terms[term]}`}
                        </MenuItem>
                      );
                    })}
                      
                    </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <FormControl 
                  fullWidth 
                  variant="outlined"  size="small" sx={{"& .MuiInputBase-root": {
                    fontSize: "14px", // Cambia este valor al tamaño de letra que desees
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px", // Cambia el tamaño de la letra de los elementos del menú
                    },}}
                >
                  <label className={styles.select_input}>Clase</label>
                  <Select
                      size="small"
                      sx={{
                        borderRadius: "10px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                        },
                      }}
                      onChange={(e) => setSelectedClass(e.target.value)}
                  >
                    {selectedCourse?.lessons[selectedTerm] &&
                      selectedCourse?.lessons[selectedTerm].map((clase) => (
                        <MenuItem value={clase.id} key={clase.id}>
                          {`${clase?.title}`}
                        </MenuItem>
                    ))} 
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <MyTextInput
                  label="Ruta"
                  name="route"
                  type="number"
                  placeholder="Ruta"
                  icon="fa fa-user"
                />
              </Grid>
              {selectedTeacher && (
                <Grid item md={12} sm={12} xs={12}>
                  {Object.entries(groupedData)?.map(([area, items]) => {
                    return(
                      <Box>
                        <p
                          style={{
                            fontSize: "1.8rem",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          {area}
                        </p>
                        {items.map((question) => {
                          const userResponse = userResponses?.find(
                            (response) => response.question === question.id
                          );
                          const answer = userResponse
                            ? userResponse.answer
                            : null;
                          const comment = userResponse
                            ? userResponse.comment
                            : "";
                          const applies = userResponse
                            ? userResponse.applies
                            : null;

                          return(
                            <>
                              <p className={styles.text_question}>
                                {question.content}
                              </p>
                              <div
                                className={styles.inputs}
                                style={{ marginTop: "0" }}
                              >
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <input
                                    type="radio"
                                    id={`answer-yes-${question.id}`}
                                    name={`answer-${question.id}`}
                                    value="true"
                                    checked={answer === true}
                                    onChange={() =>
                                      handleResponseChange(
                                        question.id,
                                        true,
                                        comment,
                                        false
                                      )
                                    }
                                  />
                                  <label htmlFor={`answer-yes-${question.id}`}>
                                    Si
                                  </label>
                                </div>
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <input
                                    type="radio"
                                    id={`answer-no-${question.id}`}
                                    name={`answer-${question.id}`}
                                    value="false"
                                    checked={
                                      applies === false && answer === false
                                    }
                                    onChange={() =>
                                      handleResponseChange(
                                        question.id,
                                        false,
                                        comment,
                                        false
                                      )
                                    }
                                  />
                                  <label htmlFor={`answer-no-${question.id}`}>
                                    No
                                  </label>
                                </div>
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <input
                                    type="radio"
                                    id={`answer-applies-${question.id}`}
                                    name={`answer-${question.id}`}
                                    value={null}
                                    checked={applies === true}
                                    onChange={() =>
                                      handleResponseChange(
                                        question.id,
                                        false,
                                        comment,
                                        true
                                      )
                                    }
                                  />
                                  <label htmlFor={`answer-no-${question.id}`}>
                                    No aplica
                                  </label>
                                </div>
                              </div>
                              <div
                                className={styles.inputs}
                                style={{ marginTop: "5px" }}
                              >
                                <div className={styles["text_area"]}>
                                  <label
                                    htmlFor={`comment-${question.id}`}
                                  >{`Comentario ${
                                    question.mandatory_comment
                                      ? "(obligatorio)"
                                      : ""
                                  }`}</label>
                                  <textarea
                                    rows={"2"}
                                    className={styles["textarea"]}
                                    value={comment}
                                    onChange={(e) =>
                                      handleResponseChange(
                                        question.id,
                                        answer,
                                        e.target.value,
                                        applies
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>

                          )

                        })}

                      </Box>

                    )
                  })}

                </Grid>

              )}
              <Grid item md={12} sm={12} xs={12}>
                <MyTextarea label="Comentarios generales" name="comments" />

              </Grid>
              <Grid item md={12} sm={12} xs={12}>
              <MyTextarea label="Feedback para el docente" name="feedback" />

              </Grid>
              {selectedTeacher2 && (
                <Grid item md={12} sm={12} xs={12}>
                <MyTextarea
                  label="Feedback para el docente 2"
                  name="feedback2"
                />
  
                </Grid>
              )}
            </Grid>
    
            <MySubmitButton name="Llenar" type="submit" />
          </Form>
        </Formik>
      </div>
      {isLoading && <LoadingComponent />}
    </Container>
  );
}

export default Planillas;
