// export const BASE_URL = "http://localhost:8000";

// export const WS_BASE_URL = "ws://localhost:8000/notifications/";
// export const WS_BASE_URL = "ws://localhost:8000/ws/chat/";

export const BASE_URL = "https://tecnikids-back.herokuapp.com";

export const WS_BASE_URL = "wss://tecnikids-back.herokuapp.com/ws/chat/";

export const REGISTER_ENDPOINT = `${BASE_URL}/auth/register/`;

export const LOGIN_ENDPOINT = `${BASE_URL}/auth/login/`;

export const LOGOUT_ENDPOINT = `${BASE_URL}/auth/logout/`;

export const RESET_PASSWORD_ENDPOINT = `${BASE_URL}/password_reset/`;

export const CONFIRM_PASSWORD_ENDPOINT = `${BASE_URL}/password_reset/confirm/`;

export const USERS_ENDPOINT = `/users/accounts/`;

export const USERS_PROFILE_ENDPOINT = `/users/profile/`;

export const LS_USER_KEY = "tkve-user";

export const USERS_FILE_REGISTER_ENDPOINT = `${BASE_URL}/upload/`;

export const USERS_ACCEPT_AGREEMENT_ENDPOINT = `${BASE_URL}/accounts/nda_agreement/`;
// INSTITUTIONS

export const INSTITUTIONS_ENDPOINT = `${BASE_URL}/institution/`;

export const INSTITUTIONS_LIST_ENDPOINT = `${BASE_URL}/institution_list/`;

export const INSTITUTIONS_LEVELS_ENDPOINT = `${BASE_URL}/institution_level/`;

// COURSES

export const LICENSES_ENDPOINT = `${BASE_URL}/license/`;

export const PROGRAMS_ENDPOINT = `${BASE_URL}/program/`;

export const COURSES_ENDPOINT = `${BASE_URL}/course/`;
export const COURSE_LIST_ENDPOINT = `${BASE_URL}/course_list/`;

export const COURSE_SECTIONS_ENDPOINT = (courseId) =>
  `${BASE_URL}/course/${courseId}/sections/`;

export const LESSONS_ENDPOINT = `${BASE_URL}/lesson/`;

export const LESSON_PROGRAM_ENDPOINT = `${BASE_URL}/lesson_program/`;

export const UPLOAD_PLANIFICATION = `${BASE_URL}/lesson/import_lessons/`;

// COURSES SECTIONS

export const SECTIONS_ENDPOINT = `${BASE_URL}/course_section/`;

export const CLASS_SCHEDULES_ENDPOINT = `${BASE_URL}/class_schedule/`;

//ANNOUNCEMENTS

export const ANNOUNCEMENTS_ENDPOINT = `${BASE_URL}/announcement/`;

export const USER_ANNOUNCEMENTS_ENDPOINT = `${BASE_URL}/announcement_user/`;

// ACADEMIC YEAR

export const ACADEMIC_YEAR = `${BASE_URL}/academic_year/`;
export const ACADEMIC_TERMS = `${BASE_URL}/academic_term/`;

// FORUMS

export const FORUMS_ENDPOINT = `${BASE_URL}/forums/`;

export const FORUM_TOPICS_ENDPOINT = `${BASE_URL}/topics/`;

export const FORUM_MESSAGES_ENDPOINT = `${BASE_URL}/topic_message/`;

//GROUPS

export const GROUPS_ENDPOINT = `${BASE_URL}/groups/`;

// FORMS

export const FORM_QUESTION_ENDPOINT = `${BASE_URL}/form_question/`;

export const FORM_QUESTION_OPTIONS_ENDPOINT = `${BASE_URL}/form_question_options/`;

export const FORM_TEMPLATE_ENDPOINT = `${BASE_URL}/form_template/`;

export const USER_FORM_ENDPOINT = `${BASE_URL}/user_form/`;

export const ANSWERS_FORM_ENDPOINT = `${BASE_URL}/user_form_answer/`;

export const SUBMIT_ANSWERS_ENDPOINT = `${BASE_URL}/user_form/submit_answers/`;

// TEACHERS

export const TEACHERS_ENDPOINT = `${BASE_URL}/teachers/`;

// DATABANK

export const DATA_ENTRIES_ENDPOINT = `${BASE_URL}/data_entry/`;

export const DATA_ENTRY_ENDPOINT = (entryId) =>
  `${BASE_URL}/data_entry/${entryId}/related/`;
// DIA DIA

export const CLASS_JOURNAL_ENDPOINT = `${BASE_URL}/class_journal/`;

// CONTACTS

export const CONTACTS_ENDPOINT = `${BASE_URL}/accounts/contacts/`;

// CHAT ROOM
export const CHAT_ROOM_ENDPOINT = `${BASE_URL}/chat/room/`;
export const MESSAGE_ENDPOINT = `${BASE_URL}/chat/message/`;

// SEGUIMIENTO
export const MONITOR_FORM_ENDPOINT = `${BASE_URL}/monitoring_form/`;
export const QUESTIONS_FORM_ENDPOINT = `${BASE_URL}/monitoring_form_question/`;
export const MONITOR_ANSWERS_FORM_ENDPOINT = `${BASE_URL}/monitoring_form_answer/`;
export const MONITOR_PICTURES_ENDPOINT = `${BASE_URL}/monitoring_pictures/`;
export const TEMPLATE_FORM_ENDPOINT = `${BASE_URL}/monitoring_form_template/`;

// SEGUIMIENTO DOCENTES REPORTS
export const  REPORTS_ENDPOINT = `${BASE_URL}/feedback_teacher/`;
export const REPORTS_GENERAL_ENDPOINT = `${BASE_URL}/feedback/`;
export const REPORTS_LIST_MONITOR = `${BASE_URL}/feedback_teacher/list_teacher/`;
// export const REPORTS_ENDPOINT_LIST = `${BASE_URL}/feedback_teacher/${termId}/feedbacks/`;

// CAPACITACIONES
export const CAPACITACION_ENDPOINT = `${BASE_URL}/training/`;
export const ASIGNACION_ENDPOINT = `${BASE_URL}/training_task/`;

//QUICES
export const QUIZ_QUESTIONS_ENDPOINT = `${BASE_URL}/training_quiz_question/`;
export const QUIZ_ENDPOINT = `${BASE_URL}/training_quiz/`;
export const QUIZ_ANSWERS_ENDPOINT = `${BASE_URL}/training_quiz_user_answer/`;
export const USER_QUIZ_ENDPOINT = `${BASE_URL}/training_quiz_user_answer/`;

//NOTIFICATIONS
export const NOTIFICATIONS_ENDPOINT = `${BASE_URL}/notification/`;
export const NOTIFICATIONS_UPDATE_ENDPOINT = `${BASE_URL}/notification/read/`;

// PASSWORD RESET CONFIRM

export const PASSWORD_RESET_ENDPOINT = `${BASE_URL}/password_reset/confirm`;

// ANNUAL TRAININGS
export const ANNUAL_TRAININGS_ENDPOINT = `${BASE_URL}/annualtraining/`;
export const ANNUAL_TRAININGS_ENDPOINT_REMINDERS = `${BASE_URL}/annualtraining/reminders/`;
export const ANNUAL_SESSIONS_ENDPOINT = `${BASE_URL}/sessions/`;
export const ANNUAL_ATTENDANCE_ENDPOINT = `${BASE_URL}/attendance/`;
export const ANNUAL_BULK_ATTENDANCE_ENDPOINT = `${BASE_URL}/attendance/bulk_create/`;
export const ANNUAL_MATERIAL_ENDPOINT = `${BASE_URL}/material/`;
export const ANNUAL_CERTIFICATE_ENDPOINT = `${BASE_URL}/certificate/`;
export const ANNUAL_CERTIFICATE_USER_ENDPOINT = `${BASE_URL}/certificate/get_by_annualtraining_user/`;
export const ATTENDANCE_INDICATORS_ENDPOINT = `${BASE_URL}/indicators/`;

// EVENTS
export const EVENTS_DATA_ENDPOINT = `${BASE_URL}/event/get_internal_data/`;
export const EVENTS_ENDPOINT = `${BASE_URL}/event/`;