const DRAWER_OPTIONS = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
    {
      name: "Anuncios",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      route: "/alertas",
    },
    {
      name: "Instituciones",
      icon: <i className="fas fa-school" aria-hidden="true"></i>,
      route: "/institutions/list",
    },
    {
      name: "Cursos",
      icon: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
      route: "/courses",
    },
    {
      name: "Seguimiento",
      icon: <i className="fas fa-file-alt"></i>,
      route: "/seguimiento",
    },
    {
      name: "Chats",
      icon: <i className="fa fa-comments" aria-hidden="true"></i>,
      route: "/chats",
    },
    {
      name: "Calendario",
      icon: <i className="fa fa-calendar" aria-hidden="true"></i>,
      route: "/calendario",
    },
    {
      name: "Encuestas",
      icon: <i className="fas fa-poll"></i>,
      route: "/encuestas",
    },
    {
      name: "Databank",
      icon: <i className="fas fa-book"></i>,
      route: "/reference",
    },
    {
      name: "Ajustes",
      icon: <i className="fa fa-cog" aria-hidden="true"></i>,
      route: "/ajustes",
    },
  ];
  
  const DRAWER_TEACHER_COORDINATOR = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
    {
      name: "Anuncios",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      route: "/alertas",
    },
    {
      name: "Instituciones",
      icon: <i className="fas fa-school" aria-hidden="true"></i>,
      route: "/institutions/list",
    },
    {
      name: "Cursos",
      icon: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
      route: "/courses",
    },
    {
      name: "Chats",
      icon: <i className="fa fa-comments" aria-hidden="true"></i>,
      route: "/chats",
    },
    {
      name: "Capacitaciones",
      icon: <i className="fas fa-file-video"></i>,
      route: "/capacitaciones",
    },
    {
      name: "Panel de actividades",
      icon: <i className="fas fa-exclamation-triangle"></i>,
      route: "/events",
    },
  ];

  const DRAWER_TEACHER_COORDINATOR_CHILE = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
    {
      name: "Anuncios",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      route: "/alertas",
    },
    {
      name: "Instituciones",
      icon: <i className="fas fa-school" aria-hidden="true"></i>,
      route: "/institutions/list",
    },
    {
      name: "Cursos",
      icon: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
      route: "/courses",
    },
    {
      name: "Capacitaciones",
      icon: <i className="fas fa-file-video"></i>,
      route: "/capacitaciones",
    },
    {
      name: "Panel de actividades",
      icon: <i className="fas fa-exclamation-triangle"></i>,
      route: "/events",
    },
  ];
  
  const DRAWER_OPTIONS_TEACHERS = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
    {
      name: "Anuncios",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      route: "/alertas",
    },
    {
      name: "Cursos",
      icon: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
      route: "/courses",
    },
    {
      name: "Chats",
      icon: <i className="fa fa-comments" aria-hidden="true"></i>,
      route: "/chats",
    },
    {
      name: "Calendario",
      icon: <i className="fa fa-calendar" aria-hidden="true"></i>,
      route: "/calendario",
    },
    {
      name: "Encuestas",
      icon: <i className="fas fa-file-alt"></i>,
      route: "/encuestas",
    },
    {
      name: "Capacitaciones",
      icon: <i className="fas fa-file-video"></i>,
      route: "/capacitaciones",
    },
    {
      name: "Panel de actividades",
      icon: <i className="fas fa-exclamation-triangle"></i>,
      route: "/events",
    },
  ];
  const DRAWER_OPTIONS_TEACHERS_CHILE = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
    {
      name: "Anuncios",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      route: "/alertas",
    },
    {
      name: "Cursos",
      icon: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
      route: "/courses",
    },
    {
      name: "Chats",
      icon: <i className="fa fa-comments" aria-hidden="true"></i>,
      route: "/chats",
    },
    {
      name: "Calendario",
      icon: <i className="fa fa-calendar" aria-hidden="true"></i>,
      route: "/calendario",
    },
    {
      name: "Encuestas",
      icon: <i className="fas fa-file-alt"></i>,
      route: "/encuestas",
    },
    {
      name: "Capacitaciones",
      icon: <i className="fas fa-file-video"></i>,
      route: "/capacitaciones",
    },
    {
      name: "Panel de actividades",
      icon: <i className="fas fa-exclamation-triangle"></i>,
      route: "/events",
    },
  ];
  
  const DRAWER_OPTIONS_TEACHER_INTERNAL = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
    {
      name: "Anuncios",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      route: "/alertas",
    },
    {
      name: "Cursos",
      icon: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
      route: "/courses",
    },
    {
      name: "Chats",
      icon: <i className="fa fa-comments" aria-hidden="true"></i>,
      route: "/chats",
    },
    {
      name: "Calendario",
      icon: <i className="fa fa-calendar" aria-hidden="true"></i>,
      route: "/calendario",
    },
    {
      name: "Encuestas",
      icon: <i className="fas fa-file-alt"></i>,
      route: "/encuestas",
    },
    {
      name: "Capacitaciones",
      icon: <i className="fas fa-file-video"></i>,
      route: "/capacitaciones",
    },
    {
      name: "Panel de actividades",
      icon: <i className="fas fa-exclamation-triangle"></i>,
      route: "/events",
    },
    {
      name: "Databank",
      icon: <i className="fas fa-book"></i>,
      route: "/reference",
    },
  ];
  
  const DRAWER_OPTIONS_SEGUIMIENTO = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
    {
      name: "Anuncios",
      icon: <i className="fa fa-bell" aria-hidden="true"></i>,
      route: "/alertas",
    },
    {
      name: "Seguimiento",
      icon: <i className="fas fa-file-alt"></i>,
      route: "/seguimiento",
    },
    {
      name: "Instituciones",
      icon: <i className="fas fa-school" aria-hidden="true"></i>,
      route: "/institutions/list",
    },
    {
      name: "Cursos",
      icon: <i className="fa fa-graduation-cap" aria-hidden="true"></i>,
      route: "/courses",
    },
    {
      name: "Chats",
      icon: <i className="fa fa-comments" aria-hidden="true"></i>,
      route: "/chats",
    },
    {
      name: "Databank",
      icon: <i className="fas fa-book"></i>,
      route: "/reference",
    },
  ];
  
  const DRAWER_OPTIONS_STUDENTS = [
    {
      name: "Home",
      icon: <i className="fa fa-home" aria-hidden="true"></i>,
      route: "/",
    },
  ];
export {DRAWER_OPTIONS,
    DRAWER_TEACHER_COORDINATOR,
    DRAWER_OPTIONS_TEACHERS,
    DRAWER_OPTIONS_TEACHER_INTERNAL,
    DRAWER_OPTIONS_SEGUIMIENTO,
    DRAWER_OPTIONS_STUDENTS,
    DRAWER_OPTIONS_TEACHERS_CHILE,
    DRAWER_TEACHER_COORDINATOR_CHILE}